@import "main/variables_przedszkole";
@import "main/importer";

@import "main/bootstrap";

.default_font { @import "main/default_font"; }
.default_form { @import "main/default_form"; }

@import "../js/plugins/select2/select2";
@import "../js/plugins/jquery_mCustomScrollbar/jquery.mCustomScrollbar.scss";

@import "main/style";

// tutaj dajesz tylko style szczególne dla theme przedszkole

.btn{
    border-radius: 24px !important;

    line-height: 44px !important;
    &:after{
        border-radius: 24px !important;
        border: 2px solid $theme-main !important;
    }
    &:hover{
        filter: none !important;
        //background-color: $opacity-main !important;
        background-color: transparent !important;
        text-decoration: underline;
        &:after{
            filter: none !important;
        }
    }
}

.przedszkole {
    .margin_bottom {
        margin-bottom: 32px;
    }
    // Efekt przybliżenia zdjęcia
    .events_list-wrapper, .shortcuts-wrapper, .institutions_list-wrapper, .gallery_item, .multimedia_block-wrapper, .gallery_list {
        .item {
            transition: 0.3s transform ease;

            &:hover {
                .preview {
                    transform: scale(0.95);
                }
            }
        }

        .preview {
            transition: 0.3s transform ease; /* Dodaj przejście dla .preview na poziomie wyższego selektora */
        }
    }
    .newest_info_block-wrapper {
        .item-list {
            a.item {
                display: inline-block;
                transition: 0.3s all ease;

                &:hover {
                    margin-left: 10px;
                    margin-right: 10px;
                }
            }
        }
    }
    // Efekt obramowania
    .schools_block {
        .item-list {
            a.item {
                //display: inline-block;
                position: relative;
                overflow: hidden; /* Ukryj część obramowania, która może wystawać */
                transition: 0.7s transform, 0.7s border, 0.7s border-radius;

                &:hover {

                    &::before {
                        content: '';
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                        border: dashed 2px $theme-main;
                        border-radius: 5px;
                        z-index: 3;
                    }
                }
            }
        }
    }

}
.btn1{
    &:hover{
        filter: none !important;
        background-color: var(--opacity-main) !important;
    }
}
.main_banner-wrapper, .activity_carousel, .warnings_block-wrapper {
    @media only screen and (max-width: 767px) {
        margin-bottom: 40px;
    }
    .main_banner {
        .slide_holder {
            > div {
                &.content_block-wrapper{
                    width: calc(100% - 416px) !important;
                    @media only screen and (max-width: 1200px) {
                        width: 50% !important;
                    }
                    @media only screen and (max-width: 767px) {
                        width: 100% !important;
                    }
                }
                &.image-wrapper{
                    width: 416px !important;
                    @media only screen and (max-width: 1200px) {
                        width: 50% !important;
                    }
                    @media only screen and (max-width: 767px) {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

div.corner-left-bottom rect, div.corner-top-right rect {
    fill: $theme-main;
}

div.heading rect, div.category rect {
    fill: $theme-main;
}

.addon_element [fill="#4e7d1e"] {
    fill: $theme-main;
}


// tutaj dajesz tylko style szczególne dla theme main
input[type="text"], input[type="password"], textarea {
    border-radius: $border-radius-8;
}
.default_form .select2-container .selection .select2-selection{
    border: 1px solid $color-gray-300;
    border-radius: $border-radius-4;
}
header {
    .top_header-wrapper {
        .top_header {
            .socials-wrapper {
                .social-list {
                    ul {
                        li {
                            a{
                                border-radius: $border-radius-4;
                            }
                        }
                    }
                }
            }
        }
    }
}

.main_banner-wrapper {
    .carousel_elements_parent-wrapper {
        background-color: $theme-dark;
        border-radius: $border-radius-12;
        .nav-wrapper {
            width: calc(100% - 416px);
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
            .dots{
                button{
                    border-color: #3A4357;
                    &.on{
                        border-color: $color_white;
                    }
                }
            }
            .btns {
                button{
                    border-radius: $border-radius-8;
                    background-color: #3A4357;
                    &:hover{
                        background-color: $color_white;
                        svg{
                            path{
                                fill: #3A4357;
                            }
                        }
                    }
                    svg{
                        path{
                            fill: $color_white;
                        }
                    }
                }
            }
        }
    }
}

.main_banner-wrapper {
    .carousel_elements_parent-wrapper {
        .main_banner{
            border-radius: $border-radius-8;
        }
    }
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .item {

                .content {
                    .date{
                        border-radius: $border-radius-8;


                        background-color: $color-gray-100;
                    }
                }
            }
        }
    }
}

.siblings_events_block-wrapper {
    .events_list-wrapper {
        .item-list {
            .preview{
                overflow: hidden;
                border-top-left-radius:$border-radius-8;
                border-top-right-radius:$border-radius-8;
            }
            .item {
                height: 100%;
                display: block;
                border-radius: $border-radius-8;
                //overflow: hidden;
                overflow: visible;
                .content{
                    border-bottom-left-radius: $border-radius-8;
                    border-bottom-right-radius: $border-radius-8;
                    border: 1px solid $color-gray-200;
                    background-color: $color_white;
                    min-height: calc(100% - 173px);
                }
            }
        }
    }
}
input[type="submit"] {

    border: 2px solid $theme_main !important;

}
.newsletter_block-wrapper {
     .newsletter_block {
         form {
             .form_group {
                 input[type="submit"] {
                     color: $color-gray-900 !important;
                     background-color: $color_white !important;
                     border: 2px solid $theme_main !important;
                     border-radius: $border-radius-4 !important;
                     &:hover{
                         filter: none;
                         //background-color: $theme-main !important;
                     }
                 }
             }
         }
     }
    .newsletter_block{
        border-radius: $border-radius-8 !important;
    }
    .block_heading-wrapper {
        .heading {
            h3 {
                color: $color-gray-900 !important;
            }
        }
    }
    .checkbox-wrapper{
        color: $color-gray-600 !important;
        a{
            color: $color-gray-600 !important;
        }
    }
}

.dark_block{
    background-color: $opacity-main !important;
    //background-color: $theme-dark;
}

footer{
    background-color: #ffffff !important;
    color: #4A5469 !important;
    .footer_bottom{
        color: $color-gray-900 !important;
        border-color: $color-gray-200 !important;
    }
    .content-wrapper {
        .parts-list {
            .part {
                .annotation {
                    color: $color-gray-600 !important;
                }
                .heading {
                    color: $color-gray-900 !important;
                }
                .contacts {
                    a,p, span {
                        color: $color-gray-600 !important;
                    }
                }
                .menu_list-wrapper {
                    .menu_list {
                        a {
                            color: $color-gray-600 !important;
                        }
                    }
                }
            }
        }
    }


}

.departments_block-wrapper {
    .departments_block {
        .block_content {
            .departments_list {
                border-radius: $border-radius-8;
            }
        }
    }
}

.send_message_block-wrapper {
    .send_message_block{
        border-radius: $border-radius-8;
    }
}

.simple_page-wrapper {
    .simple_page_block-wrapper {
        .simple_page_block {
            .block_content-wrapper {
                .faq {
                    .list {
                        .faq_item {
                            border: 2px solid $color-gray-200;
                            border-radius: $border-radius-8;
                            box-shadow: 0px 0px 16px transparent;
                        }
                    }
                }

                //Faq categories
                .faq_categories{

                    .faq_categories_list{
                        display: grid;
                        gap: 48px;

                        .faq_category_block{
                            display: grid;
                            gap: 12px;
                        }
                    }
                }


                //Map
                .map_block-wrapper{
                    //iframe{
                    //    border-radius: $border-radius-8;
                    //}
                }


                // Downloads
                .downloads {

                    .list {
                        display: grid;
                        gap: 8px;
                        width: fit-content;
                    }
                }
            }
        }
    }
}
.article_page-wrapper {
    .article_block-wrapper {
        .article_block {
            .block_content-wrapper {
                .faq {
                    .list {
                        .faq_item{
                            border: 2px solid $color-gray-200;
                            border-radius: $border-radius-8;
                            box-shadow: 0px 0px 16px transparent;
                        }
                    }
                }
            }
        }
    }
}
.downloads_item{
    background-color: $color-white;
    border: 2px solid $color-gray-200;
    border-radius: 30px;
    &:hover{
        background-color: $color-gray-100;
        .additions{
            .file_type,
            .file_size{
                background-color: $color-white !important;
                text-decoration: unset !important;
            }
        }
    }
}

.institutions_block-wrapper {
    .item-list {
        .item{
            border-radius: $border-radius-8;
            border: 1px solid $color-gray-200;
        }
    }
}

.mulitmedia_gallery_list-wrapper {
    .gallery_list{
        overflow: hidden;
        border-radius: $border-radius-8;
    }
}
.gallery_list{
    .item{
        .preview{
            height: 173px;
            img{
                height: 100% !important;
            }
        }
        .content{
            height: calc(100% - 173px);
            display: block !important;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
            .heading{
                margin-bottom: 10px;
            }
        }
    }
}
.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            > div{
                margin-bottom: 16px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
            .shortcuts-wrapper {
                .item-list {
                    .item {
                        background-color: $color-white !important;
                        border-radius: $border-radius-8 !important;
                        border: 2px solid $theme_main !important;
                        &:hover{
                            filter: none;
                            background-color: $opacity-main !important;
                        }
                        svg {
                            [fill="#4e7d1e"], path {
                                fill: unset !important;
                            }
                        }
                    }
                }
            }
        }
    }
}
.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .calendar-wrapper {
                background-color:$color_white !important;
                border-radius: $border-radius-8 !important;
                border: 2px solid $theme_main;
            }
        }
    }
}

.block_with_sidebar-wrapper {
    .sidebar-wrapper {
        .sidebar_list {
            .calendar-wrapper {
                .mycalendar2_datepicker {
                    .base_bg_color {
                        background-color: $color_white !important;
                        border-radius: $border-radius-8 !important;
                    }
                }
            }
        }
    }
}

.block_with_sidebar-wrapper{
    .sidebar-wrapper{
        .sidebar_list {
            .calendar-wrapper {
                .mycalendar2_datepicker {
                    .base_bg_color {
                        .months_change{
                            border-color: $theme_main;
                        }
                    }
                }
            }
        }
    }
}

.categories_tabs-wrapper {
    .categories_tabs {
        margin-bottom: 16px;
        .categories_tabs_list {
            .categories_tab {
                background-color: $color_white !important;
                border-radius: $border-radius-40 !important;
                color: $color-gray-600 !important;
                border: 2px solid $color-gray-200;
                &:hover,
                &.active{
                    border-color: $theme-main;
                    background-color: $theme-cta;
                    color: $theme-cta-font;
                }
            }
        }
    }
}

.institutions_block-wrapper {
    .item-list {
        .item {
            .preview {
                border-radius: $border-radius-8;
            }
        }
    }
}

.newest_info_block-wrapper {
    .item-list {
        .item {
            .preview {
                border-radius: $border-radius-8;
            }
        }
    }
}

.search_results_block-wrapper {
    .search_results_block_list-wrapper {
        .item-list {
            .search_results_block_item_list-wrapper {
                .item-list {
                    .item {
                        background-color: $color-gray-50;
                        border-radius: $border-radius-8;
                    }
                }
            }
        }
    }
}

#map {
    height: 450px;
}


.addon_element{
    path, rect{
        fill: $theme-main;
    }
}
.calendar_heading{
    rect{
        fill: $theme-main !important;
    }
}

.sidebar-wrapper, .schools_block, .location, .mail, .phone{
    path{
        fill: $color-gray-600;
    }
    rect{
        fill: transparent;
    }
}

.contacts{
    svg{
        path{
            fill: $color-gray-600 !important;
        }
    }
}
header {
    .bottom_header-wrapper {
        .bottom_header {
            .header_menu {
                > ul {

                    > li{
                        display: inline-flex;
                        vertical-align: top !important;
                        align-items: center;
                        margin-right: 0 !important;
                        > div{
                            > a{
                                padding: 22.5px 20px !important;
                            }
                        }
                         ul{
                            border-radius: 6px;
                             li{
                                 background-color: transparent !important;
                             }
                            a{
                                background-color: transparent !important;
                                border-radius: 0 !important;
                                &:hover{
                                    text-decoration: none !important;
                                }
                            }
                        }
                        &.menu_dots {
                            padding: 19px;
                        }

                        @media only screen and (max-width: 767px) {
                            width: 100%;
                            text-align: left;
                            &.menu_dots{
                                display: none !important;
                            }
                        }
                        &:last-child{
                            &:after{
                                display: none;
                            }
                        }

                        button {
                            position: relative;
                            left: -20px;
                        }
                        div{
                            display: flex;
                            vertical-align: middle;
                            &.on{
                                a{
                                    background-color: $color-gray-100;
                                    color: $color-gray-900 !important;
                                }
                            }
                            a{
                                //padding: 22.5px 20px !important;
                                border-radius: 32px;
                                transition: $transition;
                                &:hover{
                                    background-color: $color-gray-100;
                                    color: $color-gray-900 !important;
                                    text-decoration: underline;
                                }
                            }
                        }
                        &:not(.menu_dots):after{
                            content: '';
                            display: inline-block;
                            vertical-align: middle;
                            //margin: 0 20px 10px 20px;
                            width: 4px;
                            height: 4px;
                            border-radius: 50%;
                            background-color: $theme-main;
                            @media only screen and (max-width: 767px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
.menu_holder_box1{
    text-align: center;
}

.page{
    > header{
        margin-bottom: 32px;
    }
}

.multimedia_block-wrapper {
    .multimedia_block {
        .item-list {
            .item {
                background-color: $color_white;
                border-radius:  $border-radius-8;
                overflow: hidden;
                .preview{
                    border-radius: 0;
                }
                .content{
                    padding: 20px;
                }
            }
        }
    }
}

.shortcuts_block-wrapper {
    .shortcuts_block {
        .item-list {
            .item{
                &:hover{
                    border-color: $theme_main;
                    background-color: $color_white;
                }
            }
        }
    }
}

.subpage_top{
    //padding-top: 0;
    .message_category{
        font-size: 10.42px;
    }
}
.mycalendar2_datepicker {
    .calendar_content {
        header.months_change {
            .left{
                background-color: $color-gray-100;
                text-align: center !important;
                border-radius: $border-radius-4;
                width: 32px;
                height: 32px;
                vertical-align: middle;
                svg{
                    height: 12px;
                    width: auto;
                }
            }
        }
    }
}
.mycalendar2_datepicker {
    .calendar_content {
        header.months_change {
            .right{
                background-color: $color-gray-100;
                text-align: center !important;
                border-radius: $border-radius-4;
                width: 32px;
                height: 32px;
                vertical-align: middle;
                svg{
                    height: 12px;
                    width: auto;
                }
            }
        }
    }
}

.mycalendar2_datepicker {
    .calendar_content {
        header.months_change {
            .title{
                vertical-align: middle;
                width: calc(100% - 64px);
                > div{
                    display: inline-block;
                    vertical-align: middle;
                    color: $color-gray-900;
                    &.year{
                        font-weight: $unnamed-font-weight-bold;
                    }
                }
            }
        }
    }
}

.carousel_nav{
    button{
        border-radius: $border-radius-8;
    }
}

.box_with_desc{
    border-radius: $border-radius-8;
    .desc{
        border-radius: $border-radius-8;
    }
}

iframe{
    width: 100%;
}

// Footer
footer {
    .menu_list, ul{

        a {
            color: $color-gray-600 !important;
        }
    }
}

.send_message_block-wrapper {
    .send_message_block {
        .block_content {
            form {
                input[type=submit]{
                    height: 44px;
                    padding: 0 24px;
                    border-radius: 24px;
                    color: $theme-cta-font !important;
                    border: 2px solid $theme-main !important;
                    background-color: $theme-cta !important;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

.save_form_btn_holder {
    input[type="submit"] {
        height: 44px;
        padding: 0 24px;
        border-radius: 24px;
        color: $theme-cta-font !important;
        border: 2px solid $theme-main !important;
        line-height: unset !important;
        background-color: $theme-cta !important;
        &:hover{
            text-decoration: underline;
        }
    }
}


.schools_block-wrapper .schools_block .find_school .heading h2{
    color:  $theme-main !important;
}
.schools_block-wrapper .schools_block .find_school form input[type="submit"]{
    border-color: $theme-main !important;
}

.find_school{
    .notice{
        color: $color1 !important;
    }
}

.login_box{
    border-radius: 6px;
}
.przedszkole{
    .mycalendar2_datepicker .calendar_content .days_table .tr .td{
        &:hover{
            background-color: #F4F7FB !important;
        }
    }
}

.mycalendar2_datepicker .calendar_content .days_table .td.active, .mycalendar2_datepicker .calendar_content .days_table .td.active_selected{
    border: 1px solid $color-white;
}

.search_results_block-wrapper .form-wrapper form .form_group input[type="submit"]{
    color: $theme-cta-font !important;
    &:hover{
        text-decoration: underline;
        border-color: $theme-main !important;
    }
}

.footer{

}

.header_menu{
    .has_more_btn{
        line-height: 0 !important;
    }
    svg{
        path{
            fill: #000000 !important;
        }
    }
}

.myajaxlist_paginate_box .one_page.active{
    //color: #000000 !important;
    //border: 1px solid $theme-main;
    background-color: transparent !important;
    border: 1px solid $theme-cta !important;
    color: #000000 !important;
}

footer .menu_list a, footer ul a, footer a{
    color: #4A5469 !important;
}

.gray_block{
    //padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}

.cp_neutal_btn{
    border-radius: 24px !important;
}
